const styles = {
    boxWidth: "xl:max-w-[1280px] w-full",

    heading2: "font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full",
    heading3: "font-poppins font-semibold xs:text-[25px] text-[25px] text-white w-full",
    paragraph: "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px] px-5",

    label: "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]",
    input: " border text-sm rounded-lg w-full p-2.5 " +
        "bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-gray-500 focus:border-gray-500",

    flexCenter: "flex justify-center items-center",
    flexStart: "flex justify-center items-start",
    flexEnd: "flex justify-center items-end",

    paddingX: "sm:px-16 px-6",
    paddingY: "sm:py-16 py-14",
    padding: "sm:px-16 px-6 sm:py-12 py-4",

    marginX: "sm:mx-16 mx-6",
    marginY: "sm:my-16 my-6",
    textInfo: "font-poppins font-semibold xs:text-[30px] text-[30px]  text-center color-[#3b99e0]",
    innerWidth: '2xl:max-w-[1280px] w-full',
    aboutUsRow2:" text-center   text-[18px]"

};

export const layout = {
    section: `flex md:flex-row flex-col ${styles.paddingY}`,
    sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

    sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
    sectionImg: `flex-1 flex  md:ml-10 ml-0 md:mt-0 mt-10 relative py-6`,

    sectionInfo: `flex-1 ${styles.flexStart} flex-col py-6`,
    sectionInfo1: `flex-1 ${styles.flexCenter} py-6`,
};

export default styles;
