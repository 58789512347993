import React from "react";
import styles, {layout} from "./util/Style";
import {useLocation} from "react-router-dom";

const FreeTrialInProgress = () => {
    let location = useLocation()
    let message;
    let status;
    if (location.state) {
        message = location.state.message;
        status = location.state.status;
    }
    return (
        <section id="privacyPolicy" className={`${layout.section}`}>
            <div className={`${layout.sectionInfo1} text-white `}>
                <div className="container">
                    <div className="row">
                        <p className={status && status === "in-progress" ? `${styles.heading3}  mt-5 loading` : `${styles.heading3}  mt-5`}>
                            {message}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FreeTrialInProgress;
