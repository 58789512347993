export const footerLinks = [
    {
        title: "Get Started",
        links: [
            {
                name: "Content",
                link: "/",
            },
            {
                name: "Product",
                link: "product",
            },
            {
                name: "Pricing",
                link: "pricing",
            },
            {
                name: "Who We Are",
                link: "aboutUs",
            },
        ]
    },
    {
        title: "Support",
        links: [
            {
                name: "Write To Us",
                link: "",
                emailLink: "mailto:info@ssosec.com",
            }
        ]
    },
    {
        title: "Legal",
        links: [
            {
                name: "Privacy Policy",
                link: "privacyPolicy",
            }
        ]
    },
    /*
    Empty!
     */
    {
        title: "",
        links: [
            {
                name: "",
                link: "",
            }
        ]
    }
];

export const socialMedia = [
    {
        id: "social-media-1",
        icon: "fab fa-instagram-square",
        link: "https://www.instagram.com/follow_ssosec",
    },
    {
        id: "social-media-2",
        icon: "fab fa-linkedin",
        link: "https://www.linkedin.com/company/ssosec",
    },
    {
        id: "social-media-3",
        icon: "fab fa-facebook-square",
        link: "https://www.facebook.com/ssosec",
    },
    {
        id: "social-media-4",
        icon: "fab fa-twitter-square",
        link: "https://www.twitter.com/follow_ssosec",
    },
];