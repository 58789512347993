
import { motion } from 'framer-motion';
import {fadeIn} from "./Motion";
import iamPng1 from "../../assets/img/features.png"
import iamPng2 from "../../assets/img/features.png"
import iamPng3 from "../../assets/img/features.png"
import iamPng4 from "../../assets/img/features.png"
import iamPng5 from "../../assets/img/features.png"


const ExploreCard = ({ id, title, index, active, handleClick }) => {
    const chosePng = (id) => {
        let png = iamPng1;
        switch (id) {
            case 'iam-1' : return iamPng1;
            case 'iam-2' : return iamPng2;
            case 'iam-3' : return iamPng3;
            case 'iam-4' : return iamPng4;
            case 'iam-5' : return iamPng5;
        }
    }
    return (
        <motion.div
            variants={fadeIn('right', 'spring', index * 0.5, 0.75)}
            className={`relative ${
                active === id ? 'lg:flex-[3.5] flex-[10]' : 'lg:flex-[0.5] flex-[2]'
            } flex items-center justify-center min-w-[170px] h-[700px] transition-[flex] duration-[0.7s] ease-out-flex cursor-pointer`}
            onClick={() => handleClick(id)}
        >
            <img
                src={chosePng(id)}
                alt="explore"
                className="absolute w-full h-full object-cover rounded-[24px]"
            />
            {active !== id ? (
                <h3 className="font-semibold sm:text-[26px] text-[18px] text-white absolute z-0 lg:bottom-20 lg:rotate-[-90deg] lg:origin-[0,0]">
                    {title}
                </h3>
            ) : (
                <div className="absolute bottom-0 p-8 flex justify-start w-full flex-col bg-[rgba(0,0,0,0.5)] rounded-b-[24px]">

                    <h2 className="mt-[24px] font-semibold sm:text-[32px] text-[24px] text-white">
                        {title}
                    </h2>
                </div>
            )}
        </motion.div>
    );
}

export default ExploreCard;
