import React from "react";
import {Routes, Route} from "react-router-dom";
import {paths} from "./components/util/Paths";


function App() {
  return (
      <>
        <Routes>
            {paths.map((path, idx) => (
                <Route key={idx} path={path.path} element={path.element} />
            ))}
        </Routes>
      </>
  );
}

export default App;
