export const freeTrial = [
    {
        htmlFor: "workEmail",
        id: "workEmail",
        label: "Work Email",
        placeHolder: "Work Space Email",
        type: "text"
    },
    {
        htmlFor: "firstName",
        id: "firstName",
        label: "First Name",
        placeHolder: "First Name",
        type: "text"
    },
    {
        htmlFor: "lastName",
        id: "lastName",
        label: "Last Name",
        placeHolder: "Last Name",
        type: "text"
    },
    {
        htmlFor: "employeeSize",
        id: "employeeSize",
        label: "No Of Employees",
        placeHolder: "",
        type: "dropdown"
    },
    {
        htmlFor: "domain",
        id: "domain",
        label: ["Your ", <span className={"text-gradient"}>ctrl</span>, " FQDN"],
        placeHolder: "domain",
        type: "domain"
    },
    {
        htmlFor: "phoneNumber",
        id: "phoneNumber",
        label: "Phone No",
        placeHolder: "",
        type: "phoneNumber"
    },
    {
        htmlFor: "reCaptchaCode",
        id: "reCaptchaCode",
        label: "Captcha",
        placeHolder: "",
        type: "reCaptchaCode"
    },
];