import Home from "../Home";

export const paths = [
    {
        path: "/",
        element: <Home props={{context: "main", motion: true}}/>
    },
    {
        path: "product",
        element: <Home props={{context: "main", motion: true, scrollTo: "product"}}/>
    },
    {
        path: "iam",
        element: <Home props={{context: "iam", motion: true}}/>
    },
    {
        path: "freeTrial",
        element: <Home props={{context: "freeTrial", motion: false}}/>
    },
    {
        path: "privacyPolicy",
        element: <Home props={{context: "privacyPolicy", motion: false}}/>
    },
    {
        path: "aboutUs",
        element: <Home props={{context: "aboutUs", motion: false}}/>
    },
    {
        path: "pricing",
        element: <Home props={{context: "pricing", motion: false}}/>
    },
    {
        path: "freeTrialSuccess",
        element: <Home props={{context: "freeTrialSuccess", motion: false}}/>
    },
    {
        path: "freeTrialInProgress",
        element: <Home props={{context: "freeTrialInProgress", motion: false}}/>
    },
    {
        path: "freeTrialError",
        element: <Home props={{context: "freeTrialError", motion: false}}/>
    },
];
