import React, {useEffect, useRef} from "react";
import styles from "./../components/util/Style";
import {motion} from "framer-motion";
import Navbar from "./../components/Navbar";
import Hero from "./../components/Hero";
import Product from "./../components/Product";
import GetStarted from "./../components/GetStarted";
import Footer from "./../components/Footer";
import {staggerContainer} from "./util/Motion";
import FreeTrial from "./FreeTrial";
import PrivacyPolicy from "./PrivacyPolicy";
import AboutUs from "./AboutUs";
import Pricing from "./Pricing";
import FreeTrialSuccess from "./FreeTrialSuccess";
import FreeTrialInProgress from "./FreeTrialInProgress";
import FreeTrialError from "./FreeTrialError";
import IAM from "./IAM";


const Home = (props) => {

    const context = props && props.props && props.props.context;
    const wMotion = props && props.props && props.props.motion;
    const scrollTo = props && props.props && props.props.scrollTo;

    const productRef = useRef(null);

    useEffect(() => {
        if(scrollTo === "product") {
            productRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [props.props]);

    return (
        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className={`${styles.innerWidth} mx-auto flex flex-col`}
        >
            <div className="bg-primary w-full overflow-hidden">
                <div className={`${styles.paddingX} ${styles.flexCenter} bg-black-gradient-2 fixed z-40 w-full`}>
                    <div className={`${styles.boxWidth}`}>
                        <Navbar wMotion={wMotion}/>
                    </div>
                </div>
                {context === "main" &&
                    <div id={"home"} className={`${styles.flexStart} bg-black-gradient`}>
                        <div className={`${styles.boxWidth}`}>
                            <Hero />
                        </div>
                    </div>
                }
                <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter} bg-black-gradient`}>
                    <div className={`${styles.boxWidth}`}>
                        {context === "main" &&
                            <>
                                <div ref={productRef}>
                                    <Product />
                                </div>
                                <GetStarted />
                            </>
                        }
                        {context === "iam" &&
                            <IAM />
                        }
                        {context === "freeTrial" &&
                            <FreeTrial />
                        }
                        {context === "freeTrialSuccess" &&
                            <FreeTrialSuccess />
                        }
                        {context === "freeTrialInProgress" &&
                            <FreeTrialInProgress />
                        }
                        {context === "freeTrialError" &&
                            <FreeTrialError />
                        }
                        {context === "privacyPolicy" &&
                            <PrivacyPolicy />
                        }
                        {context === "aboutUs" &&
                            <AboutUs />
                        }
                        {context === "pricing" &&
                            <Pricing />
                        }
                        <Footer wMotion={wMotion}/>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default Home;
