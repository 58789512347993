import React from "react";
import styles, {layout} from "./util/Style";
import {useLocation} from "react-router-dom";

const FreeTrialSuccess = () => {
    let location = useLocation()
    let domain;
    let userName;
    if (location.state) {
        domain = location.state.domain;
        userName = location.state.workEmail;
    }
     return (
        <section id="privacyPolicy" className={`${layout.section}`}>
            <div className={`${layout.sectionInfo1} text-white `}>
                <div className="container">
                    <div className="row">
                        <p className={`${styles.heading3}  mt-5`}>
                            Thank you for registering.
                            Welcome to the family.
                        </p>
                        <p className={`${styles.paragraph} mt-2`}>
                            A confirmation email has been sent! Follow the link in the mail to begin accessing your new
                            SSOSEC account.<br/>
                            Didn't get it? Check your spam.

                        </p>
                        <p className={`${styles.paragraph} mt-5`}>
                            Save this URL so you can access your account Later.
                        </p>
                        <p className={`${styles.paragraph} mt-2`}>
                            Login: {userName}
                        </p>
                        <p className={`${styles.paragraph} mt-2`}>
                            Org URL: {domain}
                        </p>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default FreeTrialSuccess;
