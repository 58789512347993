import React from "react";
import styles, {layout} from "./util/Style";

const AboutUs = () => {
    return (
        <section id="privacyPolicy" className={`${layout.section}`}>
            <div className={`${layout.sectionInfo1} text-white `}>
                <div className="container">
                    <div className="row">
                        <p className={`${styles.heading3}  mt-5`}>
                            About Us
                        </p>
                        <p className={`${styles.paragraph} mt-5`}>
                            A team of industry veterans with a depth of experience working with internet security & analytics,
                            having individually spearheaded several multi-billion dollar projects for Fortune 100 companies.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
