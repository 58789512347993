import styles from "./util/Style";
import {motion} from "framer-motion";
import Button from "./Button";
import {fadeIn, staggerContainer} from "./util/Motion";
import {useNavigate} from 'react-router-dom'

const GetStarted = () => {
    let navigate = useNavigate();
    const freeTrail = () => {
        console.log("freeTrail")
        navigate("/freeTrial", {state: {refresh: "doRefresh"}})

    }
    return (
        <section
            className={`bg-black-gradient-2 ${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{once: true, amount: 0.25}}
                className={`${styles.innerWidth} mx-auto flex flex-col`}
            >
                <motion.div
                    variants={fadeIn('up', 'spring', 0.4, 1)}
                    className="flex md:flex-row flex-col gap-4"
                >
                    <div className="flex-1 flex flex-col">
                        <h2 className={styles.heading2}>Try now!</h2>
                    </div>

                    <div className={`${styles.flexCenter} sm:ml-10 ml-0 sm:mt-0 mt-10`}>
                        <a href="/freeTrial">
                            <Button btnText={"Free Trial"} styles={"h-[50px]"}/>
                        </a>

                    </div>
                </motion.div>
            </motion.div>
        </section>
    );
}

export default GetStarted;
