import React from "react";
import styles, {layout} from "./util/Style";

const FreeTrialError = () => {
    return (
        <section id="privacyPolicy" className={`${layout.section}`}>
            <div className={`${layout.sectionInfo1} text-white `}>
                <div className="container">
                    <div className="row">
                        <p className={`${styles.heading3}  mt-5`}>
                           Please Try Again.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FreeTrialError;
