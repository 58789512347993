import styles from "./util/Style";
import {motion} from "framer-motion";
import logo from "./../assets/img/logo.png"
import { footerLinks, socialMedia } from "./util/FooterLinks";
import {fadeIn, staggerContainer, variant} from "./util/Motion";

const FooterTop = () => {
    return (
        <div className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}>
            <div className="flex-[1] flex flex-col justify-start mr-10">
                <img
                    src={logo}
                    alt="ssosec"
                    className="w-[140px] h-[32px] object-contain"
                />
                <p className={`${styles.paragraph} mt-4 `}>
                    Your extended IT Security Team.
                </p>
                <p className={`${styles.paragraph} `}>
                    One platform for all things cybersecurity.
                </p>
            </div>
            <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10">
                {footerLinks.map((footerlink) => (
                    <div key={footerlink.title} className={`flex flex-col ss:my-0 my-4 min-w-[150px]`}>
                        <h4 className="font-poppins font-medium text-[18px] leading-[27px] text-white">
                            {footerlink.title}
                        </h4>
                        <ul className="list-none mt-4">
                            {footerlink.links.map((link, index) => (
                                <li
                                    //onClick={() =>}
                                    key={link.name}
                                    className={`font-poppins font-normal text-[16px] leading-[24px] text-dimWhite hover:text-secondary cursor-pointer ${
                                        index !== footerlink.links.length - 1 ? "mb-4" : "mb-0"
                                    }`}
                                >
                                    {link.emailLink &&
                                        <a href={`${link.emailLink}`}>{link.name}</a>
                                    }
                                    {!link.emailLink &&
                                        <a href={`${link.link}`}>{link.name}</a>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
}

const FooterBottom = () => {
    return (
        <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]">
            <p className="font-poppins font-normal text-center text-[12px] leading-[27px] text-dimWhite">
                Ⓒ 2022 SSOSEC Systems India Pvt. Ltd. All Rights Reserved.
            </p>

            <div className="flex flex-row md:mt-0 mt-6">
                {socialMedia.map((social, index) => (
                    <div key={index}
                         className={`w-[34px] h-[34px] mr-2 rounded-full ${styles.flexCenter} cursor-pointer bg-dimWhite `}>
                        <i className={`${social.icon} fa-lg`} onClick={() => window.open(social.link)}/>
                    </div>
                ))}
            </div>
        </div>
    );
}

const Footer = ({wMotion}) => {
    return (
        <>
            {wMotion &&
                <section id={"footer"} className={`${styles.flexCenter} sm:py-4 py-2 flex-col`}>
                    <motion.div
                        variants={staggerContainer}
                        initial="hidden"
                        whileInView="show"
                        viewport={{once: true, amount: 0.25}}
                        className={`${styles.innerWidth} mx-auto flex flex-col`}
                    >
                        <motion.div
                            variants={fadeIn('down', 'spring', 0.8, 1)}
                            className="flex md:flex-row flex-col gap-4"
                        >
                            <FooterTop />
                        </motion.div>
                        <motion.div
                            variants={variant('right')}
                            className={`flex-1 ${styles.flexCenter}`}
                        >
                            <FooterBottom />
                        </motion.div>
                    </motion.div>
                </section>
            }
            {!wMotion &&
                <section id={"footer"} className={`${styles.flexCenter} sm:py-4 py-2 flex-col`}>
                    <FooterTop />
                    <FooterBottom />
                </section>
            }
        </>
    );
};

export default Footer;
