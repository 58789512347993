

export const features = [
    {
        id: "feature-1",
        icon: "far fa-address-card",
        title: "IAM",
        content: [
            "Cloud Directory",
            "Universal Login",
            "SSO & MFA",
            "VAULT"
        ]
    },
    {
        id: "feature-2",
        icon: "fas fa-laptop-code",
        title: "UEM",
        content: [
            "Compliance",
            "Vulnerability Assessment",
            "Patch Management",
        ]
    },
    {
        id: "feature-3",
        icon: "fas fa-save",
        title: "DLP",
        content: [
            "Media Protection",
            "Anti-Screen Capture",
            "Anti-Key Logger"
        ]
    },
    {
        id: "feature-4",
        icon: "fas fa-globe",
        title: "SWG",
        content: [
            "Web Application and API Protection",
            "DNS Sec",
            "Remote Browser Isolation"
        ]
    }
];

export const exploreIAM = [
    {
        id: 'iam-1',
        title: 'IAM-FEATURE-1',
    },
    {
        id: 'iam-2',
        title: 'IAM-FEATURE-2',
    },
    {
        id: 'iam-3',
        title: 'IAM-FEATURE-3',
    },
    {
        id: 'iam-4',
        title: 'IAM-FEATURE-4',
    },
    {
        id: 'iam-5',
        title: 'IAM-FEATURE-5',
    },
];