import styles, {layout} from "./util/Style";
import {motion} from "framer-motion";
import {fadeIn, staggerContainer, textVariant2} from "./util/Motion";
import Typed from "react-typed";
import {useState} from "react";
import {exploreIAM} from "./util/Features";
import ExploreCard from "./util/ExploreCard";

const IAM = () => {
    const [active, setActive] = useState('iam-2');
    return (
        <section className={`sm:p-16 xs:p-8 px-6 py-12 relative z-10`}>
            <br />
            <br/>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className={`${styles.innerWidth} mx-auto flex-col`}
            >
                <Typed
                    className='mt-[8px] font-normal text-[18px] text-dimWhite'
                    strings={['extensive flexible adaptive']}
                    typeSpeed={50}
                    showCursor={false}
                />
                <motion.p
                    variants={fadeIn('up', 'tween', 0.2, 1)}
                    className="mt-[8px] font-normal sm:text-[32px] text-[20px] text-center text-dimWhite"
                >
                    &nbsp;<span className="font-extrabold text-white">SSOSEC</span>&nbsp;
                    is a new thing in the future, where you can secure users apps and devices
                    &nbsp;<span className="font-extrabold text-white">DIRECTORY</span>&nbsp;
                    is a new thing in the future, where you can secure users apps and devices
                    &nbsp;<span className="font-extrabold text-white">UNIVERSAL LOGIN</span>&nbsp;
                    is a new thing in the future, where you can secure users apps and devices
                    &nbsp;<span className="font-extrabold text-white">SSO & MFA</span>&nbsp;
                    is a new thing in the future, where you can secure users apps and devices
                    &nbsp;<span className="font-extrabold text-white">VAULT</span>&nbsp;
                    is a new thing in the future, where you can secure users apps and devices
                </motion.p>
                <motion.h2
                    variants={textVariant2}
                    initial="hidden"
                    whileInView="show"
                    className={`mt-[8px] font-bold md:text-[64px] text-[40px] text-white text-center`}
                >
                    Put a title here
                </motion.h2>
                <div className="mt-[50px] flex lg:flex-row flex-col min-h-[70vh] gap-5">
                    {exploreIAM.map((iam, index) => (
                        <ExploreCard
                            key={iam.id}
                            {...iam}
                            index={index}
                            active={active}
                            handleClick={setActive}
                        />
                    ))}
                </div>

            </motion.div>
        </section>
    );
};

export default IAM;