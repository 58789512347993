import React from "react";
import styles, {layout} from "./util/Style";
import {features} from "./util/Features";
import {motion} from "framer-motion";
import {fadeIn, variant, staggerContainer} from "./util/Motion";

const FeatureCard = ({ icon, title, content, index }) => (
    <>
        <div className={`flex flex-row p-6 rounded-[20px] ${index !== features.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
            <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimWhite `}>
                <i className={`${icon} fa-2x`}/>
            </div>
            <motion.div
                variants={fadeIn('left', 'tween', 0.4, 1)}
                className="flex-[0.75] flex justify-center flex-col"
            >
                <div className="flex-1 flex flex-col ml-3">
                    <h4 className="font-poppins font-semibold text-white text-[18px] leading-[23.4px] mb-1">
                        {title}
                    </h4>
                    {content.map((cont, index) => (
                        <p key={index} className="ml-5 px-5 font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
                            {cont}
                        </p>
                    ))}
                </div>
            </motion.div>
        </div>
    </>
);

const Product = () => {
    return (
        <section id="product" className={`${layout.section}`}>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className={`${styles.innerWidth} mx-auto flex lg:flex-row flex-col gap-8`}
            >
                <motion.div
                    variants={variant('left')}
                    className={`flex-1 ${styles.flexCenter}`}
                >
                    <div className={`${layout.sectionInfo}`}>
                        <h2 className={`${styles.heading2}`}>
                            <span className={"text-gradient text-[100px]"}>ctrl</span>
                        </h2>
                        <p className={`${styles.heading2}  mt-5 px-5`}>
                            security as a service platform.
                        </p>
                        <p className={`${styles.paragraph} max-w-[470px] mt-2`}>
                            Provides 360&#176; protection from cyber-attacks.
                        </p>
                        <p className={`${styles.paragraph} max-w-[470px] mt-2`}>
                            An integrated IAM + UEM + DLP + SWG platform.
                        </p>
                    </div>
                </motion.div>
                <motion.div
                    variants={fadeIn('left', 'tween', 0.2, 1)}
                    className="flex-[0.75] flex justify-center flex-col"
                >
                    <div className={`${layout.sectionImg} flex-col`}>
                        {features.map((feature, index) => (
                            <FeatureCard key={feature.id} {...feature} index={index} />
                        ))}
                    </div>
                </motion.div>
            </motion.div>
        </section>
    );
};

export default Product;
