export const navLinks = [
    {
        id: "product",
        title: "Product",
    },
    {
        id: "pricing",
        title: "Pricing",
    },
    {
        id: "aboutUs",
        title: "Who We Are",
    }
];