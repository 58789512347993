import styles from "./Style";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";

const InputGroup = ({
                        _htmlFor, _label, _id, _placeHolder, _type, reconcileData,
                        emailError, captchaError, firstNameErr, lastNameError, fqdnError, phoneError
                    }) => {

    const [phoneValue, setPhoneValue] = useState();
    const onChange = (evt) => {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        reconcileData(evt.target.name, value)
    }
    const onCaptchaChange = (value) => {
        reconcileData("reCaptchaCode", value)
    }
    const onPhoneChange = (value) => {
        reconcileData("phoneNumber", value)
    }

    return (
        <>
            <div className={`py-2`}>
                <label htmlFor={_htmlFor} className={`${styles.label} max-w-[470px] mt-5`}>{_label}</label>
                {_type === "text" &&
                    <>
                        <input type="text" id={_id} name={_id} className={`${styles.input} block`}
                               placeholder={_placeHolder} required onChange={onChange}/>
                        {
                            _id === "workEmail" && emailError &&
                            <label htmlFor={_htmlFor} className="text-red-400 text-sm">{"Invalid Email"}</label>
                        }
                        {
                            _id === "firstName" && firstNameErr &&
                            <label htmlFor={_htmlFor} className="text-red-400 text-sm">{"Enter First Name"}</label>
                        }
                        {
                            _id === "lastName" && lastNameError &&
                            <label htmlFor={_htmlFor} className="text-red-400 text-sm">{"Enter Last Name"}</label>
                        }


                    </>
                }
                {_type === "domain" &&
                    <>
                        <div>
                            <span className={`${styles.label} px-2`}>https://</span>
                            <input type="text" id={_id} name={_id} className={`${styles.input} w-3/5`}
                                   placeholder={_placeHolder} onChange={onChange}
                                   required/>
                            <span className={`${styles.label} px-2`}>.ssosec.com</span>
                        </div>
                        {
                            fqdnError &&
                            <label htmlFor={_htmlFor} className="text-red-400 text-sm">{"Enter FQDN"}</label>
                        }

                    </>
                }
                {_type === "dropdown" &&
                    <select className={`${styles.input}`} id={_id} name={_id}  onChange={onChange}>
                        <option value="1-5">1-5</option>
                        <option value="5-25">5-25</option>
                        <option value="25-50">25-50</option>
                        <option value="50-100">50-100</option>
                        <option value="100-500">100-500</option>
                        <option value="500-1000">500-1000</option>
                        <option value="1000+">1000+</option>
                    </select>
                }
                {_type === "phoneNumber" &&
                    <>
                        <PhoneInput
                            placeholder="Enter phone number"
                            onChange={onPhoneChange}
                            value={phoneValue}
                            className={`${styles.input} px-2 w-full md:w-4/5`}
                        />
                        {
                            phoneError &&
                            <label htmlFor={_htmlFor} className="text-red-400 text-sm">{"Enter Phone"}</label>
                        }
                    </>
                }
                {_type === "reCaptchaCode" &&
                    <>
                        <ReCAPTCHA onChange={onCaptchaChange} sitekey='6LfmIs4iAAAAAJqdjMH5D0B7h8qPjWTsfebdDWG1'
                                   size="normal" theme="dark"/>
                        {
                            _id === "reCaptchaCode" && captchaError &&
                            <label htmlFor={_htmlFor} className="text-red-400 text-sm">{"Please Select Captcha"}</label>
                        }
                    </>
                }
            </div>
        </>

    );
}

export default InputGroup;