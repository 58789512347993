import React from "react";
import styles, {layout} from "./util/Style";

const PrivacyPolicy = () => {
    return (
        <section id="privacyPolicy" className={`${layout.section}`}>
            <div className={`${layout.sectionInfo1}`}>
                <div className="container">
                    <div className="row">
                        <p className={`${styles.heading3}  mt-5`}>
                            Privacy is important to SSOSEC Systems India Private Limited.
                        </p>
                        <p className={`${styles.paragraph} mt-2`}>
                            In this Privacy Policy, "SSOSEC," "we," "our," and "us" each mean SSOSEC Systems India
                            Private Limited.
                        </p>
                        <p className={`${styles.paragraph} mt-2`}>
                            Our products are:
                        </p>
                        <div className={`${styles.marginX} ${styles.paragraph} mt-2`}>
                            <ol style={{listStyleType: "decimal"}}>
                                <li>SSOSEC Ctrl software.</li>
                                <li>SSOSEC Ctrl cloud service.</li>
                                <li>SSOSEC Trust desktop App.</li>
                                <li>SSOSEC Trust mobile App.</li>
                                <li>SSOSEC Trust Browser Plugin.</li>
                            </ol>
                        </div>
                        <p className={`${styles.paragraph}  mt-2`}>
                            In this Privacy Policy, "services" means our products and this website.
                        </p>

                        <p className={`${styles.paragraph}  mt-2`}>
                            This Privacy Policy governs information we collect from you when you use any of our
                            Services.
                        </p>

                        <p className={`${styles.paragraph}  mt-2`}>
                            This page is used to inform you about our policies with regards to the collection, use, and
                            disclosure of Personal Information if anyone decided to use our services.
                        </p>

                        <p className={`${styles.paragraph}  mt-2`}>
                            If you choose to use our services, then you agree to the collection and use of information
                            in relation with this policy. The Personal Information that we collect are used for
                            providing and improving our services. We will not use or share your information with anyone
                            except as described in this Privacy Policy.
                        </p>

                        <h3 className={`${styles.heading3} mt-5`}>Information Collection and Usage</h3>
                        <p className={`${styles.paragraph}  mt-2`}>
                            For a better experience while using our services, we may require you to provide us with
                            certain personally identifiable information, including but not limited to your name, phone
                            number, and postal address. The information that we collect will be used to contact or
                            identify you.
                        </p>

                        <h3 className={`${styles.heading3} mt-5`}>Cookies</h3>
                        <p className={`${styles.paragraph}  mt-2`}>
                            We use Cookies in our applications based on requirements. We don't fetch any personal
                            information in the cookies.
                        </p>

                        <h3 className={`${styles.heading3} mt-5`}>Service Providers</h3>
                        <p className={`${styles.paragraph}  mt-2`}>
                            We may employ third-party companies and individuals due to the following reasons:
                            <div className={`${styles.marginX} ${styles.paragraph} mt-2`}>
                                <ol style={{listStyleType: "decimal"}}>
                                    <li>To facilitate our Service</li>
                                    <li>To provide the Services on our behalf</li>
                                    <li>To assist us in analyzing how our Service is used.</li>
                                </ol>
                            </div>
                        </p>
                        <p className={`${styles.paragraph}  mt-2`}>
                            We want to inform our service users that these third parties have access to your Personal
                            Information. The reason is to perform the tasks assigned to them on our behalf. However,
                            they are obligated not to disclose or use the information for any other purpose.
                        </p>

                        <h3 className={`${styles.heading3} mt-5`}>Security</h3>
                        <p className={`${styles.paragraph}  mt-2`}>
                            We value your trust in providing us your Personal Information, thus we are striving to use
                            commercially acceptable means of protecting it. But remember that no method of transmission
                            over the internet, or method of electronic storage is 100% secure and reliable, and we
                            cannot guarantee its absolute security.
                        </p>

                        <h3 className={`${styles.heading3} mt-5`}>Links to Other Sites</h3>
                        <p className={`${styles.paragraph}  mt-2`}>
                            Our Service may contain links to other sites. If you click on a third-party link, you will
                            be directed to that site. Note that these external sites are not operated by us. Therefore,
                            we strongly advise you to review the Privacy Policy of these websites. We have no control
                            over, and assume no responsibility for the content, privacy policies, or practices of any
                            third-party sites or services.
                        </p>

                        <h3 className={`${styles.heading3} mt-5`}>Children's Privacy</h3>
                        <p className={`${styles.paragraph}  mt-2`}>
                            Our Services do not address anyone under the age of 13. We do not knowingly collect personal
                            identifiable information from children under 13. In the case we discover that a child under
                            13 has provided us with personal information, we immediately delete this from our servers.
                            If you are a parent or guardian and you are aware that your child has provided us with
                            personal information, please contact us so that we will be able to do necessary actions.
                        </p>

                        <h3 className={`${styles.heading3} mt-5`}>Changes to This Privacy Policy</h3>
                        <p className={`${styles.paragraph}  mt-2`}>
                            We may update our Privacy Policy from time to time. Thus, we advise you to review this page
                            periodically for any changes. We will notify you of any changes by posting the new Privacy
                            Policy on this page. These changes are effective immediately, after they are posted on this
                            page.
                        </p>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;
