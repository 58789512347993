import React, {useEffect, useState} from "react";
import styles, {layout} from "./util/Style";
import InputGroup from "./util/InputGroup";
import {freeTrial} from "./util/InputGroupData";
import Button from "./Button";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";

const FreeTrial = () => {
    let navigate = useNavigate();
    let location = useLocation();

    const [trailData, setTrailData] = useState({
        workEmail: "",
        firstName: "",
        lastName: "",
        employeeSize: "1-5",
        domain: "",
        phoneNumber: "",
        reCaptchaCode: ""

    })
    const [emailError, setEmailError] = useState(false)
    const [captchaError, setCaptchaError] = useState(false)
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [fqdnError, setFqdnError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)

    useEffect(() => {
    }, [])

    const validateOnSave = () => {
        let retVal = true
        setEmailError(false)
        setCaptchaError(false)
        setFirstNameError(false)
        setLastNameError(false)
        setFqdnError(false)
        setPhoneError(false)
        if (!validateCaptcha(trailData.reCaptchaCode)) {
            setCaptchaError(true)
            retVal = false
        }
        if (trailData.workEmail === "") {
            setEmailError(true)
            retVal = false
        }
        if (trailData.phoneNumber === "") {
            setPhoneError(true)
            retVal = false
        }
        if (trailData.firstName === "") {
            setFirstNameError(true)
            retVal = false
        }
        if (trailData.lastName === "") {
            setLastNameError(true)
            retVal = false
        }
        if (trailData.domain === "") {
            setFqdnError(true)
            retVal = false
        }
        return retVal
    }


    const onSave = (evt) => {
        evt.preventDefault()

        if (!validateOnSave()) {
            return
        }
        navigate("/freeTrialInProgress", {state: {message: "Processing your request",status:"in-progress"}})
        axios.create({headers: {'Content-Type': 'application/json'}})
            .post('https://onboard.ssosec.com/onboard/request', trailData)
            .then((response) => {
                let id = response.data.requestId
                let count = 0
                let repeatCall = setInterval(() => {
                    if (count >= 4) {
                        clearInterval(repeatCall)
                        navigate("/freeTrialInProgress", {
                            state: {
                                message: "Looks like it's taking longer than expected." +
                                    "Please check your mail in a few minutes." +
                                    "No worries, we will get back to you."
                            }
                        })

                    } else {
                        axios.create({headers: {'Content-Type': 'application/json'}})
                            .post('https://onboard.ssosec.com/onboard/status', {"requestId": id})
                            .then((response) => {
                                if (response.data.status === "success") {
                                    clearInterval(repeatCall)
                                    navigate("/freeTrialSuccess", {state: {domain:response.data.domain,workEmail:response.data.workEmail}});
                                } else if (response.data.status === "in-progress") {
                                    navigate("/freeTrialInProgress", {state: {message: "Processing your request",status:"in-progress"}})

                                    setTimeout(() => {
                                        console.log("settime out")
                                    }, 5 * 1000)
                                    console.log("settime out")
                                } else {
                                    clearInterval(repeatCall)
                                    navigate("/freeTrialInProgress", {state: {message: "Something went wrong. No worries, we will get back to you."}})
                                }
                                count = count + 1
                            })
                            .catch((err) => {
                                clearInterval(repeatCall)
                                navigate("/freeTrialError")

                            })
                    }
                }, 6000)


            })
            .catch((err) => {
                navigate("/freeTrialError")
            })


    }

    const validateCaptcha = (captchaVal) => {
        if (captchaVal === "") {
            return false
        } else {
            return true
        }

    }
    const validateEmail = (value) => {
        if (value === "") {
            setEmailError(false)
            return true
        }
        let emailReg = /\S+@\S+\.\S+/.test(value)
        if (emailReg) {
            setEmailError(false)
        }
        return emailReg;
    }

    const reconcileData = (name, value) => {
        if (name === "workEmail") {
            if (!validateEmail(value)) {
                setEmailError(true)
            }
        }
        if (name === "domain") {
            value = "https://" + value + ".ssosec.com"
        }
        setTrailData({
            ...trailData,
            [name]: value
        });
    }


    return (
        <section id="freeTrial" className={`${layout.section}`}>
            <div className={`${layout.sectionInfo}`}>
                <h2 className={`${styles.heading3}`}>
                    Start your 30 days free trial now.
                </h2>
                <p className={`${styles.paragraph} mt-5`}>
                    Protect your organisation from security threats.
                </p>
                <p className={`${styles.paragraph} max-w-[470px]`}>
                    Provides 360&#176; protection from cyber-attacks.
                </p>
                {/*<i className="fa fa-laptop" style={{fontSize: "400px"}} />*/}
            </div>
            <div className={`${layout.sectionImg} flex-col text-white`}>
                <form id="profile" name="profile" onSubmit={onSave}>
                    {freeTrial.map((trial, index) => (
                        <div key={index}>
                            <InputGroup
                                _htmlFor={trial.htmlFor} _id={trial.id}
                                _label={trial.label} _placeHolder={trial.placeHolder}
                                _type={trial.type} reconcileData={reconcileData}
                                emailError={emailError} captchaError={captchaError}
                                firstNameErr={firstNameError}
                                lastNameError={lastNameError}
                                fqdnError={fqdnError}
                                phoneError={phoneError}

                            />
                        </div>

                    ))}
                    <Button styles={"w-[100px] h-[40px] mt-3"} btnText={"Submit"} onClick={onSave}/>
                </form>
            </div>
        </section>
    );
};

//

export default FreeTrial;
